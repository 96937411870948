import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';



const routes: Routes = [

  { path: 'auth',
   loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  canActivate: [AuthGuard],
},
  { path: 'pages', 
  loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  canActivate: [AuthGuard],

 },  {
  path: 'web',
  loadChildren: () =>
    import('./pages/web/web.module').then((m) => m.WebModule),
  canActivate: [AuthGuard],
},
{
  path: 'support',
  loadChildren: () =>
    import('../app/pages/suport/suport.module').then((m) => m.SuportModule),
  // canActivate: [AuthGuard],
},
  { path:'',
   pathMatch:"full",
    redirectTo:'/auth/login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
