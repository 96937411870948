import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { apiList } from './services/http/api-list';
import { Constant } from './core/constant';
import { AgmCoreModule } from '@agm/core';
import { HttpInterceptorService } from './services/interceptor/http-interceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NumberModuleModule } from '../app/number-module/number-module.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';



@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    NgxIntlTelInputModule,
    HttpClientModule,
    NgxUiLoaderModule,
    FormsModule,
    ReactiveFormsModule,
    NumberModuleModule,
    AgmCoreModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    apiList,
    Constant,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
