export class apiList {
  login: string = '/admin/login';
  logout: string = '/admin/logout';
  changePassword: string = '/admin/changePassword';
  forgotPassword: string = '/admin/forgotPasswordWithEmail';
  profile: string = '/admin/getProfile';
  updateProfile: string = '/admin/updateProfile';
  uploadFile: string = '/admin/uploadFile';
  users: string = '/admin/users';
  categories: string = '/admin/categories';
  subcategories: string = '/admin/subcategories';
  allSubCategories: string = '/admin/allSubCategories';
  provider:string = '/admin/provider';
  providerRequests:string = '/admin/providerRequests';
  geofences:string = '/admin/geofences';
  cms:string = '/admin/cms';
  faqCms:string='/admin/faqCms'
  getAllOrders:string='/admin/getAllOrders'
  getOrderDetail:string='/admin/getOrderDetail'
  updateOrderStatus:string='/admin/updateOrderStatus'
  ratings:string='/admin/ratings'
  getUsers:string='/admin/getUsers'
  getProviders:string='/admin/getProviders'
  sendNotification:string='/admin/sendNotification'
  getAllNotifications='/admin/getAllNotifications'
  accessModules='/admin/accessModules'
  getAccessModules: string = '/admin/getAccessModules';
  updateAccessModules: string = '/admin/updateAccessModules';
  deleteAccessModules: string = '/admin/deleteAccessModuless';
  signupSubAdmin: string = '/admin/signupSubAdmin';
  getSubAdmin: string = '/admin/getSubAdmin';
  updateSubAdmin: string = '/admin/updateSubAdmin';
  deleteSubAdmin: string = '/admin/deleteSubAdmin';
  dashboard: string = '/admin/dashboard';
  getRevenueGraph: string = '/admin/getRevenueGraph';
  getProviderGraph: string = '/admin/getProviderGraph';
  getUserGraph: string = '/admin/getUserGraph';
  getBookingGraph: string = '/admin/getBookingGraph';
  getGeoFences: string = '/admin/getGeoFences';
  getRevenue: string = '/admin/getRevenue';
  getReportingGraph: string = '/admin/getReportingGraph';
  getWalletTransactions: string = '/admin/getWalletTransactions'; 
  getUserOrders: string = '/admin/getUserOrders'; 
  getOneOrderUser: string = '/admin/getOneOrderUser'; 
  trackUserOrder: string = '/admin/trackUserOrder'; 
  getAllProviderOrders: string = '/admin/getAllProviderOrders'; 
}
